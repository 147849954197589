import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from '../BrowserHistory/History.js';

const Home = () =>
    (
        <div className="Home Grid Grid--equalHeight Grid--withGutter">
            <div className="Grid-cell u-size1of2">
                <div className="Login-form u-sizeFill">
                    <h2 className="Login-form-title">Account Log In</h2>
                    <div className="Login-form-fieldset">
                        <div className="Login-form-fieldset-field">
                            <label className="Login-form-fieldset-field-label">E-Mail</label>
                            <input
                                className="Login-form-fieldset-field-email"
                                type="email"
                                placeholder="e.g. name@server.com"
                                defaultValue="account@gmail.com" />
                        </div>
                        <div className="Login-form-fieldset-field">
                            <label className="Login-form-fieldset-field-label">Password</label>
                            <input
                                className="Login-form-fieldset-field-password"
                                type="password"
                                defaultValue="1234567890" />
                        </div>
                    </div>
                    <Link to="/account" className="Login-form-submit">LOGIN</Link>
                    <div className="Login-form-link">
                        <a href="#">Forgot Password?</a>
                    </div>
                </div>
            </div>
            <div className="Grid-cell u-size1of2">
                <div className="Grid-column Grid-column--withGutter">
                    <div className="Grid-cell">
                        <div className="Login-form">
                            <h2 className="Login-form-title">
                              Existing member but need an online account?
                            </h2>
                            <p>
                                {'Get the convenience of online Account management today.'
                                + ' Register today new account.'}
                            </p>
                            <button className="Login-button">REGISTER NOW</button>
                        </div>
                    </div>
                    <div className="Grid-cell">
                        <div className="Login-form">
                            <h2 className="Login-form-title">Have a Question?</h2>
                            <p>Have a question? Please visit our support center.</p>
                            <button className="Login-button">{'FAQ\'S'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

Home.propTypes = {
    onSuccess: PropTypes.func,
    history: PropTypes.object,
};

export default showIfLocation(Home);
