import {fankUrl} from '../config/constants';
import $ from 'jquery';

const requestLinkAccountsCb = (data, successCb) => {
    createAccounts(data.memberId, function() {
        $.ajax({
            type: 'POST',
            url: '/link/accounts',
            data: JSON.stringify(data),
            success: function(res) {
                successCb(res);
            },
            contentType: 'application/json',
        });
    });
};

const createAccounts = (bankId, memberId, country, profile, successCb) => {
    $.ajax({
        type: 'POST',
        url: '/create/accounts',
        data: JSON.stringify({bankId, memberId, country, profile}),
        success: function(res) {
            successCb(res);
        },
        contentType: 'application/json',
    });
};

const getAuthRequestPayload = (bic, id, successCb) => {
    $.ajax({
        type: 'GET',
        url: `${fankUrl}/banks/${bic}/auth-request-payloads/${id}`,
        success: function(res) {
            successCb(res);
        },
    });
};

const getLinkingRequest = (bic, id, successCb) => {
    $.ajax({
        type: 'GET',
        url: `${fankUrl}/banks/${bic}/linking-requests/${id}`,
        success: function(res) {
            successCb(res);
        },
    });
};

const getConsentRequest = (bic, id, successCb) => {
    $.ajax({
        type: 'GET',
        url: `${fankUrl}/banks/${bic}/consent-requests/${id}`,
        success: function(res) {
            successCb(res);
        },
    });
};

const createResourceTypeAccessConsent = (bic, clientId, requestId, accountNumbers, successCb) => {
    $.ajax({
        type: 'POST',
        url: `${fankUrl}/banks/${bic}/consents`,
        data: JSON.stringify({
            clientId,
            requestId,
            resourceTypeAccess: {
                accountNumbers,
            },
        }),
        success: function(res) {
            successCb(res);
        },
        contentType: 'application/json',
    });
};

const createAccountResourceAccessConsent = (bic, clientId, requestId, successCb) => {
    $.ajax({
        type: 'POST',
        url: `${fankUrl}/banks/${bic}/consents`,
        data: JSON.stringify({
            clientId,
            requestId,
            accountResourceAccess: {},
        }),
        success: function(res) {
            successCb(res);
        },
        contentType: 'application/json',
    });
};

const createTransferConsent = (bic, clientId, requestId, accountNumber, successCb) => {
    $.ajax({
        type: 'POST',
        url: `${fankUrl}/banks/${bic}/consents`,
        data: JSON.stringify({
            clientId,
            requestId,
            transfer: {
                accountNumber,
            },
        }),
        success: function(res) {
            successCb(res);
        },
        contentType: 'application/json',
    });
};

const createStandingOrderConsent = (bic, clientId, requestId, accountNumber, successCb) => {
    $.ajax({
        type: 'POST',
        url: `${fankUrl}/banks/${bic}/consents`,
        data: JSON.stringify({
            clientId,
            requestId,
            standingOrder: {
                accountNumber,
            },
        }),
        success: function(res) {
            successCb(res);
        },
        contentType: 'application/json',
    });
};

const createBulkTransferConsent = (bic, clientId, requestId, accountNumber, successCb) => {
    $.ajax({
        type: 'POST',
        url: `${fankUrl}/banks/${bic}/consents`,
        data: JSON.stringify({
            clientId,
            requestId,
            bulkTransfer: {
                accountNumber,
            },
        }),
        success: function(res) {
            successCb(res);
        },
        contentType: 'application/json',
    });
};

export default {
    createAccounts,
    requestLinkAccountsCb,
    getAuthRequestPayload,
    getLinkingRequest,
    getConsentRequest,
    createResourceTypeAccessConsent,
    createAccountResourceAccessConsent,
    createTransferConsent,
    createStandingOrderConsent,
    createBulkTransferConsent,
};
