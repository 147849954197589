/* eslint-disable no-unused-vars*/
import style from './Login.css';
import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from '../BrowserHistory/History.js';
import {getBankId} from '../../config/util';

class Code extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: props.params.get('country'),
            memberId: props.params.get('memberId'),
            bankId: getBankId(),
        };
    }
    render() {
        return (
            <div className="Login">
                <div className="Login-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Enter security code</h2>
                        <div className="Bank-form-fieldset">
                            <div className="Bank-form-fieldset-field">
                                <label className="Bank-form-fieldset-field-label">
                        Launch the HSBC mobile app on your device.
                                </label>
                                <input
                                    className="Bank-form-fieldset-field-memberId"
                                    type="text"
                                    placeholder="5165122"
                                    defaultValue="5165122" />
                            </div>
                        </div>
                        <div className="Bank-form-remember">
                            <a href="#">
                                {'Security code wont work?'}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Login-footer">
                    <Link
                        to={`/auth/token?memberId=${this.state.memberId}`
                        + `&country=${this.state.country}`
                        + `&bankId=${this.state.bankId}`}
                        className="Bank-form-submit">
                    Next
                    </Link>
                </div>
            </div>
        );
    }
}

Code.propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
};

export default showIfLocation(Code);
