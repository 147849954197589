/* eslint-disable no-unused-vars, max-len, no-console*/
import style from './index.css';
import React from 'react';
import bank from '../../bank/bank';
import PropTypes from 'prop-types';

class TokenEnablerButton extends React.Component {
    constructor(props) {
        super(props);
        this.bindLinkAccounts = this.bindLinkAccounts.bind(this);
    }

    componentDidMount() {
        this.bindLinkAccounts();
    }

    bindLinkAccounts() {
        if (!window.Token) {
            window.setTimeout(this.bindLinkAccounts, 100);
            return;
        }
        const onSuccess = this.props.onSuccess;

        // Get id attribute
        const buttonId = this.buttonRef.getAttribute('id');

        // Destroy previous button instance
        if (this.button) {
            this.button.destroy();
        }

        this.button = window.Token.createTokenButton(this.buttonRef, {
            id: buttonId,
            label: 'Enable Token',
        });

        // Bind link accounts button
        this.button.bindLinkAccountsButton && this.button.bindLinkAccountsButton(
            bank.requestLinkAccountsCb,
            onSuccess,
            function(error) { // Error callback
                console.log('Failed to pair accounts.', error);
            },
        );
    }

    render() {
        return (
            <div id='myTokenButton' className="TokenEnablerPlaceholder" ref={ref => {
                this.buttonRef = ref;
            }}>
            </div>
        );
    }
}

TokenEnablerButton.propTypes = {
    onSuccess: PropTypes.func,
};

export default TokenEnablerButton;
