/* eslint-disable max-len, camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {getBankId, formatCurrency, parseRef, formatDate, formatFrequency} from '../../config/util';
import bank from '../../bank/bank';
import {bankConfig} from '../../config/constants';
import {showIfLocation} from '../BrowserHistory/History.js';

class Confirmation extends React.Component {
    constructor(props) {
        super(props);

        this.pair = this.pair.bind(this);
        this.createConsent = this.createConsent.bind(this);
        const data = props.params.get('data') ? props.params.get('data') : '';
        const profile = props.params.get('profile') ? props.params.get('profile') : '';
        const sourceAccountHash = props.params.get('sourceAccountHash') ? props.params.get('sourceAccountHash') : '';
        const authRequestId = props.params.get('auth-request-id') || '';
        const callbackUrl = props.params.get('callback-url') || '';
        const ref = parseRef(props.params.get('ref'));
        const payload = parseRef(props.params.get('payload'));
        const consentRequest = parseRef(props.params.get('consent-request'));
        const requestId = props.params.get('request-id');
        const clientId = props.params.get('client-id');
        const accountNumber = parseRef(props.params.get('accountNumber'));
        const bankId = getBankId();
        this.state = {
            bankId,
            data,
            ref,
            profile,
            authRequestId,
            sourceAccountHash,
            callbackUrl,
            payload,
            consentRequest,
            requestId,
            clientId,
            accountNumber: accountNumber || ref.accountNumber,
            processing: false,
        };
    }

    // Sends the desired accounts, memberId, and client ID, to the backend, and redirects to url
    // with linking payloads
    pair() {
        this.setState({
            processing: true,
        });

        const {bankId, data, profile, accountNumber, sourceAccountHash, authRequestId, callbackUrl} = this.state;
        if (sourceAccountHash) {
            document.location = '/generate-bank-authorization'
                + `?bankId=${bankId}`
                + `&accounts=${accountNumber}`
                + `&profile=${profile}`
                + `&sourceAccountHash=${sourceAccountHash}`
                + `&auth-request-id=${authRequestId}`
                + `&callback-url=${encodeURIComponent(callbackUrl)}`;
        } else {
            // Change location instead of AJAX call, because we want phone to detect the url,
            // parse the page content (which will be json), and close the iframe.
            document.location = '/generate-bank-authorization'
                + `?bankId=${bankId}`
                + `&data=${data}`
                + `&accounts=${accountNumber}`
                + `&profile=${profile}`;
        }
    }

    createConsent() {
        this.setState({
            processing: true,
        });

        const {consentRequest, requestId, clientId, accountNumber, callbackUrl} = this.state;
        const selectedAccount = accountNumber.split('|')[0];
        if (consentRequest.transferBody) {
            bank.createTransferConsent(
                bankConfig[getBankId()].bic,
                clientId,
                requestId,
                selectedAccount,
                res => {
                    if (res.consentId) {
                        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}`;
                    } else {
                        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=13&message=Consent%20Id%20is%20missing`;
                    }
                });
        } else if (consentRequest.standingOrderBody) {
            bank.createStandingOrderConsent(
                bankConfig[getBankId()].bic,
                clientId,
                requestId,
                selectedAccount,
                res => {
                    if (res.consentId) {
                        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}`;
                    } else {
                        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=13&message=Consent%20Id%20is%20missing`;
                    }
                });
        } else if (consentRequest.bulkTransferBody) {
            bank.createBulkTransferConsent(
                bankConfig[getBankId()].bic,
                clientId,
                requestId,
                selectedAccount,
                res => {
                    if (res.consentId) {
                        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}`;
                    } else {
                        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=13&message=Consent%20Id%20is%20missing`;
                    }
                });
        } else {
            document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=12&message=UnImplemented`;
        }
    }

    getDetails() {
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            return this.getDirectBankDetails(
                this.state.accountNumber.split('|')[0],
                this.state.consentRequest.transferBody,
                this.state.consentRequest.standingOrderBody,
                this.state.consentRequest.bulkTransferBody,
                this.state.consentRequest.tppName,
                this.state.consentRequest.refId);
        } else if (this.state.payload) { // direct guest pisp
            return this.getDirectBankDetails(
                this.state.accountNumber.split('|')[0],
                this.state.payload.transfer,
                this.state.payload.standingOrder,
                this.state.payload.bulkTransfer,
                this.state.payload.to.alias.value,
                this.state.payload.refId);
        } else { // coopt pisp
            const {
                provider,
                referenceId,
                amount,
                currency,
                accountNumber,
            } = this.state.ref;

            const formattedAmount = formatCurrency(amount, currency);

            const accountId = accountNumber && accountNumber.split('|')[0];

            return this.state.ref ? (
                <dl className="Payment-panel">
                    {(accountNumber ? [
                        <dt key={'account-t'}>Account number</dt>,
                        <dd key={'account-d'}>{accountId}</dd>,
                    ] : null)}

                    {(provider ? [
                        <dt key={'provider-t'}>Provider</dt>,
                        <dd key={'provider-d'}>{provider}</dd>,
                    ] : null)}

                    {(referenceId ? [
                        <dt key={'reference-t'}>Reference</dt>,
                        <dd key={'reference-d'}>{referenceId}</dd>,
                    ] : null)}

                    {(amount && currency ? [
                        <dt key={'amount-t'}>Amount</dt>,
                        <dd key={'amount-d'}>{formattedAmount}</dd>,
                    ] : null)}
                </dl>
            ) : null;
        }
    }

    getDirectBankDetails(accountNumber, transferBody, standingOrderBody, bulkTransferBody, name, refId) {
        const amount = transferBody ? (transferBody.lifetimeAmount || transferBody.amount) : standingOrderBody ? standingOrderBody.amount : bulkTransferBody.totalAmount;
        const currency = transferBody ? transferBody.currency : standingOrderBody ? standingOrderBody.currency : bulkTransferBody.transfers[0].currency;
        const executionDate = transferBody ? transferBody.executionDate : null;
        const frequency = standingOrderBody ? formatFrequency(standingOrderBody.frequency) : null;
        const startDate = standingOrderBody ? standingOrderBody.startDate : null;
        const endDate = standingOrderBody ? standingOrderBody.endDate : null;
        const beneficiary = name;
        return (
            <dl className="Payment-panel">
                {this.state.accountNumber && [
                    <dt key={'account-t'}>Account number</dt>,
                    <dd key={'account-d'}>{accountNumber}</dd>,
                ]}

                {refId && [
                    <dt key={'reference-t'}>Reference</dt>,
                    <dd key={'reference-d'}>{refId}</dd>,
                ]}

                {amount && currency && [
                    <dt key={'amount-t'}>Amount</dt>,
                    <dd key={'amount-d'}>{formatCurrency(amount, currency)}</dd>,
                ]}

                {executionDate && [
                    <dt key={'executionDate-t'}>Date</dt>,
                    <dd key={'executionDate-d'}>{formatDate(executionDate)}</dd>,
                ]}

                {startDate && [
                    <dt key={'startDate-t'}>Start Date</dt>,
                    <dd key={'startDate-d'}>{formatDate(startDate)}</dd>,
                ]}

                {endDate && [
                    <dt key={'endDate-t'}>End Date</dt>,
                    <dd key={'endDate-d'}>{formatDate(endDate)}</dd>,
                ]}

                {frequency && [
                    <dt key={'frequency-t'}>Frequency</dt>,
                    <dd key={'frequency-d'}>{frequency}</dd>,
                ]}

                {beneficiary && [
                    <dt key={'beneficiary-t'}>Beneficiary</dt>,
                    <dd key={'beneficiary-d'}>{beneficiary}</dd>,
                ]}
            </dl>
        );
    }

    render() {
        return (
            <div className="Token">
                <div className="Token-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Complete Payment</h2>
                        <p>
                            {"Please check the details below are correct. If so, select 'Confirm', or select 'Deny' if you no longer wish to continue."}
                        </p>
                        <p className="Bank-form-small">
                        Security awareness: we will never ask you to transfer
                        money, provide more than three digits from your memorable
                        information, or ask for your banking card pin number(s).
                        </p>
                        {this.getDetails()}
                    </div>
                </div>
                <div className="Token-footer-2col">
                    <div
                        onClick={() => {
                            return;
                        }}
                        className="Bank-button">
                      Deny
                    </div>
                    <div
                        onClick={() => {
                            if (!this.state.processing) {
                                this.props.route === 'v2'
                                    ? this.createConsent()
                                    : this.pair();
                            }
                        }}
                        className="Bank-form-submit">
                      Confirm
                    </div>
                </div>
            </div>);
    }
}

Confirmation.propTypes = {
    location: PropTypes.object,
    route: PropTypes.string,
    params: PropTypes.object,
};

export default showIfLocation(Confirmation);
