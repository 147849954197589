let browserEnv = {};
let bankConfig = {};

// Allow configuration of environment variables through browser environment
if (window !== undefined && window.BROWSER_ENV !== undefined) {
    browserEnv = JSON.parse(window.BROWSER_ENV);
}

// Load bank configs if available
if (window !== undefined && window.BANK_CONFIG !== undefined) {
    bankConfig = JSON.parse(window.BANK_CONFIG);
}
export const tokenEnv = browserEnv.tokenEnv;
export const DEMO_PORTAL_ITEM = browserEnv.demoPortalItem;
export const DEMO_PORTAL_URL = browserEnv.demoPortalUrl;
export const DEFAULT_BANK_ID = browserEnv.defaultBankId;
export const countries = browserEnv.countries;
export const currencySymbols = browserEnv.currencySymbols;
export const fankUrl = browserEnv.fankUrl;
export {bankConfig};

// Months
export const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sept',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};
