import {DEFAULT_BANK_ID, currencySymbols, months} from './constants';

export function generateNonce() {
    return Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15);
}

export function getBankId() {
    const bankIdQuery = getParameterByName('bankId');
    const bankIdQuery2 = getParameterByName('bank-id');
    return bankIdQuery
        ? bankIdQuery
        : bankIdQuery2
            ? bankIdQuery2
            : (DEFAULT_BANK_ID ? DEFAULT_BANK_ID : '');
}

function getParameterByName(name) {
    const url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getMemberId(memberId, data, authId) {
    if (memberId) return memberId;
    if (authId) return generateNonce();
    try {
        const {state} = parseRef(data);
        const {clientState} = parseRef(state);
        const {memberId: actualMemberId} = parseRef(clientState);
        return actualMemberId.replace(/:/g, '_');
    } catch (e) {
        throw new Error('Invalid data, state, or memberId');
    }
}

// Decode and parse ref json, add account id key and return Base64 encoded string
export function parseRef(ref) {
    try {
        return JSON.parse(window.atob(ref));
    } catch (e) {
        return '';
    }
}

export const formatCurrency = (amount, currency) => currency
    ? `${currencySymbols[currency]} ${formatNumber(amount)}`
    : '';

export const formatNumber = value => {
    const nf = new Intl.NumberFormat(
        'en',
        {
            maximumFractionDigits: 2,
        },
    );
    return nf.format(value);
};

export const mapAccount = account => account ? ({
    id: account.account_number + '|' + account.name + '|' + account.bic,
    title: account.name,
    value: formatCurrency(account.balance.value, account.balance.currency),
}) : undefined;

export const transformAccounts = accounts =>
    accounts.map(o => mapAccount(o));

export const filterAccounts = (raw, selected) => {
    const filtered = raw.filter(acc =>
        (selected.map(s => (s.split('|')[0])).includes(acc.account_number)));
    return filtered.map(acc => ({
        accountNumber: acc.account_number,
        balance: acc.balance,
        name: acc.name,
    }));
};

export const formatDate = date => {
    if (!date) return undefined;
    const year = date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date[8] === '0' ? date.substring(9) : date.substring(8);
    let str = 'th';
    if (day === '1') str = 'st';
    if (day === '2') str = 'nd';
    if (day === '3') str = 'rd';
    return `${day}${str} ${months[month]} ${year}`;
};

export const formatFrequency = frequency => {
    const frequencies =
        {
            DAIL: 'Daily',
            WEEK: 'Weekly',
            TOWK: 'Biweekly',
            MNTH: 'Monthly',
            TOMN: 'Bimonthly',
            QUTR: 'Quarterly',
            SEMI: 'Semi annually',
            YEAR: 'Annually',
        };
    return frequencies[frequency];
};
