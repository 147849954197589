/* eslint-disable no-unused-vars, max-len*/
import style from './Token.css';
import React from 'react';
import bank from '../../bank/bank';
import {filterAccounts, getBankId, getMemberId, transformAccounts, parseRef} from '../../config/util';
import {bankConfig, tokenEnv} from '../../config/constants';
import fankApi from '../../api/api';
import axios from 'axios';
import PropTypes from 'prop-types';
import {showIfLocation} from '../BrowserHistory/History.js';

class CafConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.pair = this.pair.bind(this);
        const country = props.params.get('country') ? props.params.get('country') : '';
        const data = props.params.get('data') ? props.params.get('data') : '';
        const profile = props.params.get('profile') ? props.params.get('profile') : '';
        const bankId = getBankId();
        const callbackUrl = props.params.get('callback-url') || '';
        const requestId = props.params.get('request-id');
        const tokenUsername = props.params.get('memberId')
            ? getMemberId(props.params.get('memberId'))
            : window.btoa(profile);
        let bankAccount = {};
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            const consentRequest = parseRef(props.params.get('consent-request'));
            bankAccount = consentRequest.accountResourceList.resources[0].bankAccount;
        }

        this.state = {
            clientId: '',
            accounts: [],
            country,
            bankId,
            data,
            profile,
            selected: [],
            processing: false,
            bankAccount,
            callbackUrl,
            requestId,
        };

        // Sends a request to the backend to create a few fake accounts, and perform fake linking with the
        // accounts
        bank.createAccounts(bankId, tokenUsername, country, profile, res => {
            const accounts = transformAccounts(
                res.accounts,
            );
            this.setState({
                clientId: res.clientId,
                accounts,
                selected: accounts.map(acc => acc.id),
                rawAccounts: res.accounts,
            });
        });
    }

    componentDidMount() {
        if (this.props.params.get('request_id')) { // direct guest aisp (CAF)
            const requestId = this.props.params.get('request_id');
            const url = tokenEnv === 'prd' ? `https://api.token.io/token-requests/${requestId}`
                : `https://api.${tokenEnv}.token.io/token-requests/${requestId}`;
            axios.get(url)
                .then(res => {
                    if (res.data.tokenRequest.requestPayload.accessBody.accountResourceList) {
                        const bankAccount = res.data.tokenRequest.requestPayload.accessBody.accountResourceList.resources[0].bankAccount;
                        this.setState({bankAccount});
                    } else return;
                })
                /* eslint-disable no-console */
                .catch(e => console.error(e));
            /* eslint-enable no-console */
        }
    }

    // Get access token from fank and redirect to the provided redirect_uri with access token attached as a fragment
    pair() {
        this.setState({
            processing: true,
        });

        // Token memberId and bankId pulled from URL
        const tokenMemberId = getMemberId(this.props.params.get('memberId'));
        let accountsStr = '';
        this.state.selected.map(acc =>
            accountsStr += `&accounts=${acc}`);
        const redirectUri = this.props.params.get('redirect_uri') || '';

        if (this.props.location.pathname === '/authorize/cafConfirm'
            && !bankConfig[this.state.bankId]['is-coopt']
            && !bankConfig[this.state.bankId]['is-test']) {
            const selectedAccounts = filterAccounts(this.state.rawAccounts, this.state.selected);
            fankApi.getAccessToken(this.state.rawAccounts[0].bic, tokenMemberId, this.state.profile, selectedAccounts)
                .then(function(res) {
                    document.location = `${decodeURIComponent(redirectUri)}#access_token=${res.data.accessToken}&access-token=${res.data.accessToken}&expires-in=${res.data.expiresInMs}`;
                });
        } else if (bankConfig[this.state.bankId]['is-test']) {
            document.location = `${decodeURIComponent(redirectUri)}#access_token=${tokenMemberId}&access-token=${tokenMemberId}`;
        } else {
            document.location = '/generate-bank-authorization'
                + `?clientId=${this.state.clientId}`
                + `&bankId=${this.state.bankId}`
                + `&country=${this.state.country}`
                + `&memberId=${tokenMemberId}`
                + `&profile=${this.state.profile}`
                + `&data=${this.state.data}`
                + accountsStr;
        }
    }

    createConsent() {
        this.setState({
            processing: true,
        });
        const {requestId, clientId, callbackUrl} = this.state;

        bank.createAccountResourceAccessConsent(
            bankConfig[getBankId()].bic,
            clientId,
            requestId,
            res => {
                if (res.consentId) {
                    document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}`;
                } else {
                    document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=13&message=Consent%20Id%20is%20missing`;
                }
            });
    }

    getAccountNumber() {
        const {bankAccount, requestId, callbackUrl} = this.state;
        if (bankAccount.iban) {
            return bankAccount.iban.iban;
        } else if (bankAccount.domestic) {
            return bankAccount.domestic.accountNumber;
        } else if (bankAccount.custom) {
            return bankAccount.custom.payload.split('-')[1];
        } else if (bankAccount.swift) {
            // Backwards compatibility.
            return bankAccount.swift.iban;
        } else {
            document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=3&message=Invalid%20bank%20account%20type.`;
        }
    }

    render() {
        const buttonDisabled = this.state.processing;
        const buttonClass = 'Bank-form-submit' + (buttonDisabled ? ' Bank-form-submit--disabled' : '');
        return (
            <div className="Token">
                <div className="Token-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Request to Confirm Funds</h2>
                        <p>{'Do you want to confirm whether you have sufficient funds for this account?'}</p>
                        <div className="Bank-form-fieldset">
                            <div className="Bank-form-fieldset-field">
                                <div className="Bank-form-list">
                                    <dl className="Payment-panel">
                                        {this.state.selected[0] && [
                                            <dt key={'account-t'}>Account number</dt>,
                                            <dd key={'account-d'}>{this.getAccountNumber()}</dd>,
                                        ]}
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Token-footer">
                    <div
                        onClick={() => {
                            if (!buttonDisabled) {
                                this.props.route === 'v2'
                                    ? this.createConsent()
                                    : this.pair();
                            }
                        }}
                        className={buttonClass}>
                        Approve
                    </div>
                </div>
            </div>);
    }
}

CafConfirm.propTypes = {
    onSuccess: PropTypes.func,
    location: PropTypes.object,
    route: PropTypes.string,
    params: PropTypes.object,
};

export default showIfLocation(CafConfirm);
