/* eslint-disable no-unused-vars*/
import style from './index.css';
/* eslint-enable no-unused-vars*/
import React, {Fragment} from 'react';
import Outer from '../Pages';
import Home from '../Pages/Home';
import Login from '../Pages/Login';
import LoginHSBC from '../Pages/LoginHSBC';
import LoginHSBCUsername from '../Pages/LoginHSBCUsername';
import Question from '../Pages/Question';
import Code from '../Pages/Code';
import Token from '../Pages/Token';
import Account from '../Pages/Account';
import Consent from '../Pages/Consent';
import SCA from '../Pages/SCA';
import Confirmation from '../Pages/Confirmation';
import CafConfirm from '../Pages/CafConfirm.js';
import SelectAccount from '../Pages/SelectAccount';
import OuterSimple from '../Pages/OuterSimple';
import {getBankId} from '../../config/util';
import {withHistory} from '../BrowserHistory/History.js';

const isHSBC = getBankId().toLowerCase() === 'hsbc';

class App extends React.Component {
    render() {
        return (
            <Fragment>
                <OuterSimple path={'^\\/auth/'}>
                    {isHSBC ? <LoginHSBC path={'^\\/auth/login$'}></LoginHSBC>
                        : <Login path={'^\\/auth/login$'}></Login>}
                    <LoginHSBCUsername path={'^\\/auth/login-memberId$'}></LoginHSBCUsername>
                    <Question path={'^\\/auth/question$'}></Question>
                    <Code path={'^\\/auth/code$'}></Code>
                    <Token path={'^\\/auth/token$'}></Token>
                    <Consent path={'^\\/auth/consent$'}></Consent>
                    <SCA path={'^\\/auth/sca$'}></SCA>
                    <Confirmation path={'^\\/auth/confirmation$'}></Confirmation>
                    <SelectAccount path={'^\\/auth/select-account$'}></SelectAccount>
                </OuterSimple>
                <OuterSimple path={'^\\/authorize'}>
                    <Login path={'^\\/authorize$'} route={'oauth'}></Login>
                    <SCA path={'^\\/authorize/sca$'} route={'oauth'}></SCA>
                    <Token path={'^\\/authorize/select$'} route={'oauth'}></Token>
                    <CafConfirm path={'^\\/authorize/cafConfirm$'} route={'oauth'}></CafConfirm>
                    <Login path={'^\\/authorize/v2$'} route={'v2'}></Login>
                    <SCA path={'^\\/authorize/v2/sca$'} route={'v2'}></SCA>
                    <SelectAccount path={'^\\/authorize/v2/pis-select-account$'} route={'v2'}>
                    </SelectAccount>
                    <Token path={'^\\/authorize/v2/ais-select-account$'} route={'v2'}></Token>
                    <CafConfirm path={'^\\/authorize/v2/caf-confirm$'} route={'v2'}></CafConfirm>
                    <Confirmation
                        path={'^\\/authorize/v2/confirmation$'} route={'v2'}>
                    </Confirmation>
                </OuterSimple>
                <Outer path={'^\\/$'}><Home /></Outer>
                <Outer path={'^\\/account$'}><Account /></Outer>
            </Fragment>
        );
    }
}

export default withHistory(App);
