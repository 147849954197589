/* eslint-disable no-unused-vars, max-len*/
import style from './Token.css';
import React from 'react';
import bank from '../../bank/bank';
import WebCheckboxList from '../WebCheckboxList';
import WebCheckbox from '../WebCheckbox';
import {filterAccounts, getBankId, getMemberId, transformAccounts, parseRef} from '../../config/util';
import {bankConfig} from '../../config/constants';
import fankApi from '../../api/api';
import PropTypes from 'prop-types';
import {showIfLocation} from '../BrowserHistory/History.js';

class Token extends React.Component {
    constructor(props) {
        super(props);
        this.pair = this.pair.bind(this);
        this.handleChange = this.handleChange.bind(this);
        const linkingRequestId = props.params.get('linking-request-id') ? props.params.get('linking-request-id') : '';
        const country = props.params.get('country') ? props.params.get('country') : '';
        const data = props.params.get('data') ? props.params.get('data') : '';
        const profile = props.params.get('profile') ? props.params.get('profile') : '';
        const bankId = getBankId();
        const callbackUrl = props.params.get('callback-url') || '';
        const requestId = props.params.get('request-id');
        const consentRequest = parseRef(props.params.get('consent-request'));

        this.state = {
            clientId: '',
            accounts: [],
            country,
            bankId,
            data,
            profile,
            selected: [],
            processing: false,
            callbackUrl,
            requestId,
            consentRequest,
        };

        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            bank.createAccounts(
                bankId,
                window.btoa(profile),
                country,
                profile, res => {
                    const accounts = transformAccounts(
                        res.accounts,
                    );
                    this.setState({
                        clientId: res.clientId,
                        accounts: accounts,
                        selected: accounts.map(acc => acc.id),
                        rawAccounts: res.accounts,
                    });
                });
        } else if (linkingRequestId) { // direct guest AIS
            // Fetches memberId and callbackUrl from the backend
            bank.getLinkingRequest(bankConfig[getBankId()].bic, linkingRequestId, res1 => {
                // Sends a request to the backend to create a few fake accounts, and updates the
                // UI to show the accounts
                bank.createAccounts(bankId, res1.memberId, country, profile, res2 => {
                    const accounts = transformAccounts(
                        res2.accounts,
                    );
                    this.setState({
                        memberId: res1.memberId,
                        callbackUrl: res1.callbackUrl,
                        clientId: res2.clientId,
                        accounts,
                        selected: accounts.map(acc => acc.id),
                        rawAccounts: res2.accounts,
                    });
                });
            });
        } else {
            // Sends a request to the backend to create a few fake accounts, and updates the
            // UI to show the accounts
            const tokenMemberId = getMemberId(props.params.get('memberId'));
            bank.createAccounts(bankId, tokenMemberId, country, profile, res => {
                const accounts = transformAccounts(
                    res.accounts,
                );
                this.setState({
                    memberId: tokenMemberId,
                    callbackUrl: this.props.params.get('redirect_uri') || '',
                    clientId: res.clientId,
                    accounts,
                    selected: accounts.map(acc => acc.id),
                    rawAccounts: res.accounts,
                });
            });
        }
    }

    // Get access token from fank and redirect to the provided redirect_uri with access token attached as a fragment
    pair() {
        this.setState({
            processing: true,
        });

        // Token memberId and bankId pulled from URL
        let accountsStr = '';
        this.state.selected.map(acc =>
            accountsStr += `&accounts=${acc}`);
        const memberId = getMemberId(this.state.memberId);
        const callbackUrl = this.state.callbackUrl;

        if (this.props.location.pathname === '/authorize/select'
            && !bankConfig[this.state.bankId]['is-coopt']
            && !bankConfig[this.state.bankId]['is-test']) {
            const selectedAccounts = filterAccounts(this.state.rawAccounts, this.state.selected);
            fankApi.getAccessToken(this.state.rawAccounts[0].bic, memberId, this.state.profile, selectedAccounts)
                .then(function(res) {
                    document.location = `${callbackUrl}#access_token=${res.data.accessToken}&access-token=${res.data.accessToken}&expires-in=${res.data.expiresInMs}`;
                });
        } else if (bankConfig[this.state.bankId]['is-test']) {
            document.location = `${callbackUrl}#access_token=${memberId}&access-token=${memberId}`;
        } else {
            document.location = '/generate-bank-authorization'
                + `?clientId=${this.state.clientId}`
                + `&bankId=${this.state.bankId}`
                + `&country=${this.state.country}`
                + `&memberId=${memberId}`
                + `&profile=${this.state.profile}`
                + `&data=${this.state.data}`
                + accountsStr;
        }
    }

    createConsent() {
        this.setState({
            processing: true,
        });

        const {requestId, clientId, callbackUrl} = this.state;
        const selectedAccountList = this.state.selected
            .map(acc => acc.split('|')[0]);

        bank.createResourceTypeAccessConsent(
            bankConfig[getBankId()].bic,
            clientId,
            requestId,
            selectedAccountList,
            res => {
                if (res.consentId) {
                    document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}`;
                } else {
                    document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=13&message=Consent%20Id%20is%20missing`;
                }
            });
    }

    getTitles() {
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            const permissions = this.state.consentRequest.resourceTypeList.resources
                .map( permission => <li className="Bank-form-permission" key={permission}>{permission}</li> );
            return (
                <div>
                    <h2 className="Bank-form-title">Request to Access</h2>
                    <p>Give the following permissions to {this.state.consentRequest.tppName}?</p>
                    <ul>
                        {permissions}
                    </ul>
                </div>
            );
        } else {
            return (
                <div>
                    <h2 className="Bank-form-title">Enable Token</h2>
                    <p>Give the following permissions to Token?</p>
                </div>
            );
        }
    }

    // Handle a checkbox being checked or unchecked
    handleChange(value) {
        if (this.state.selected === value) return;
        this.setState({
            selected: value,
        });
    }

    render() {
        const buttonDisabled = this.state.selected.length === 0 || this.state.processing;
        const buttonClass = 'Bank-form-submit' + (buttonDisabled ? ' Bank-form-submit--disabled' : '');
        return (
            <div className="Token">
                <div className="Token-content">
                    <div className="Bank-form">
                        {this.getTitles()}
                        <div className="Bank-form-fieldset">
                            <div className="Bank-form-fieldset-field">
                                <label className="Bank-form-fieldset-field-label">Accounts</label>
                                <div className="Bank-form-list">
                                    {this.state.accounts.length ? (
                                        <WebCheckboxList
                                            onChange={this.handleChange}
                                            items={this.state.accounts}
                                            selected={this.state.selected}
                                            type={WebCheckboxList.CHECKBOX_TYPE_INCLUSIVE} />
                                    ) : null}
                                </div>
                            </div>
                            <div className="Bank-form-fieldset-field">
                                <label className="Bank-form-fieldset-field-label">Information</label>
                                <div className="Bank-form-list">
                                    <WebCheckbox checked enabled={false} title="Shipping addresses" />
                                    <WebCheckbox checked enabled={false} title="Email address" />
                                    <WebCheckbox checked enabled={false} title="Name" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Token-footer">
                    <div
                        onClick={() => {
                            if (!buttonDisabled) {
                                this.props.route === 'v2'
                                    ? this.createConsent()
                                    : this.pair();
                            }
                        }}
                        className={buttonClass}>
                      Approve
                    </div>
                </div>
            </div>);
    }
}

Token.propTypes = {
    onSuccess: PropTypes.func,
    location: PropTypes.object,
    route: PropTypes.string,
    params: PropTypes.object,
};

export default showIfLocation(Token);
