/* eslint-disable no-unused-vars, max-len*/
import style from './Login.css';
import React from 'react';
import PropTypes from 'prop-types';
import {getBankId, getMemberId, parseRef} from '../../config/util';
import {bankConfig} from '../../config/constants';
import querystring from 'querystring';
import {showIfLocation} from '../BrowserHistory/History.js';

class Login extends React.Component {
    getNextStep() {
        const baseUrl = '/auth';
        const profile = this.usernameField.value;
        const bankId = getBankId();
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            const checkoutQueries = querystring.stringify({
                ['bank-id']: bankId,
                profile: profile,
                ['request-id']: this.props.params && this.props.params.get('request-id') || '',
                ['callback-url']: this.props.params && this.props.params.get('callback-url') || '',
            });
            return `/authorize/v2/sca?${checkoutQueries}`;
        }

        const ref = parseRef(this.props.params.get('ref'));
        const parsedData = parseRef(this.props.params.get('data'));
        const redirectUri = parsedData.redirectUrl || this.props.params.get('redirect_uri') || '';
        const requestId = this.props.params.get('request_id') || '';
        const defaults = {
            memberId: getMemberId(
                this.props.params.get('memberId'),
                this.props.params.get('data'),
                this.props.params.get('auth-request-id')),
            bankId: bankId,
            profile: profile,
            audience: this.props.params.get('audience') || '',
            scope: this.props.params.get('scope') || '',
            nonce: this.props.params.get('nonce') || '',
            state: this.props.params.get('state') || '',
            ref: this.props.params.get('ref') || '',
            data: this.props.params.get('data') || '',
            ['redirect_uri']: redirectUri,
            ['request_id']: requestId,
            ['linking-request-id']: this.props.params.get('linking-request-id') || '',
        };
        const query = querystring.stringify(Object.assign({}, defaults));
        if (this.props.params.get('auth-request-id')) { // direct guest pisp
            const authRequestQueries = querystring.stringify({
                memberId: defaults.memberId,
                bankId: defaults.bankId,
                profile: defaults.profile,
                ['auth-request-id']: this.props.params.get('auth-request-id') || '',
                ['callback-url']: this.props.params.get('callback-url') || '',
            });
            return `${baseUrl}/sca?${authRequestQueries}`;
        }
        if (bankConfig[defaults.bankId]['is-coopt']) { // coopt pisp
            if (ref) {
                if (ref.amount > 30) return `${baseUrl}/sca?${query}`;
                if (ref.accountNumber) return `${baseUrl}/confirmation?${query}`;
                return `${baseUrl}/select-account?${query}`;
            }
        }
        if (this.props.route === 'oauth') { // coopt aisp or direct guest aisp
            return `/authorize/sca?${query}`;
        }
        return `${baseUrl}/token?${query}`; // direct linking
    }

    render() {
        return (
            <div className='Login'>
                <div className='Login-content'>
                    <div className='Bank-form'>
                        <h2 className='Bank-form-title'>Account Login</h2>
                        <p className='Bank-form-instruct'>
                        In order to link with Token you must log in
                        </p>
                        <div className='Bank-form-fieldset'>
                            <div className='Bank-form-fieldset-field'>
                                <label className='Bank-form-fieldset-field-label'>Bank Username</label>
                                <input
                                    className='Bank-form-fieldset-field-memberId'
                                    type='text'
                                    placeholder='johnsmith'
                                    defaultValue='johnsmith'
                                    ref={username => this.usernameField = username} />
                            </div>
                            <div className='Bank-form-fieldset-field'>
                                <label className='Bank-form-fieldset-field-label'>Password</label>
                                <input
                                    className='Bank-form-fieldset-field-password'
                                    type='password'
                                    defaultValue='1234567890' />
                            </div>
                        </div>
                        <div className='Bank-form-remember'>
                            {'Can\'t remember something? '}
                            <a href='#'>Get Help</a>
                        </div>
                    </div>
                    <div className='Bank-terms'>
                      By pressing login you agree to our<br />
                        <a href='#'>Privacy Policy</a>
                        {' & '}
                        <a href='#'>Terms of Service</a>
                    </div>
                </div>
                <div className='Login-footer'>
                    <div
                        className='Bank-form-submit'
                        onClick={() => {
                            const url = this.getNextStep();
                            this.props.history.push(url);
                        }}>
                            Login
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    onSuccess: PropTypes.func,
    location: PropTypes.object,
    route: PropTypes.string,
    params: PropTypes.object,
    history: PropTypes.object,
};

Login.contextTypes = {
    router: PropTypes.object,
};

export default showIfLocation(Login);
