import React from 'react';
import {createBrowserHistory} from 'history';
import PropTypes from 'prop-types';

const history = createBrowserHistory();
const HistoryContext = React.createContext(history);

export const withHistory = Component => function HistoryComponent(props) {
    return (
        <HistoryContext.Consumer>
            {history => <Component {...props} history={history} />}
        </HistoryContext.Consumer>
    );
};

export const showIfLocation = Component => {
    class Sub extends React.Component {
        isPath() {
            const {path, history} = this.props;
            const regex = new RegExp(path);
            return regex && regex.test(history.location.pathname);
        }
        componentDidMount() {
            const {history} = this.props;
            this.unlisten = history.listen(location => {
                this.forceUpdate();
            });
        }
        componentWillUnmount() {
            this.unlisten();
        }
        render() {
            return this.isPath()
                ? <Component {...this.props} location={this.props.history.location}
                    params={new URLSearchParams(this.props.history.location.search)} />
                : null;
        }
    }
    Sub.propTypes = {
        path: PropTypes.string,
        history: PropTypes.object,
    };
    return withHistory(Sub);
};

export const Link = ({to, ...props}) => {
    return (
        <HistoryContext.Consumer>
            {history => (<a href={to}
                onClick={e => {
                    e.preventDefault();
                    history.push(to);
                }}
                {...props}
            />)}
        </HistoryContext.Consumer>
    );
};

Link.propTypes = {
    to: PropTypes.string,
};
