/* eslint-disable no-unused-vars, max-len*/
import style from './index.css';
import React from 'react';
import {getBankId} from '../../config/util';
import PropTypes from 'prop-types';
import {showIfLocation} from '../BrowserHistory/History.js';

class OuterSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bankId: getBankId(),
        };
    }
    render() {
        return (
            <span className={this.state.bankId + '-theme'}>
                <div className="header--logo">
                    <img src={`/bank-logo/${this.state.bankId}`} className="logo" />
                </div>
                {this.props.children || ''}
            </span>
        );
    }
}

OuterSimple.propTypes = {
    children: PropTypes.node,
};

export default showIfLocation(OuterSimple);
