/* eslint-disable max-len*/
import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import bank from '../../bank/bank';
import WebCheckboxList from '../WebCheckboxList';
import {
    getBankId,
    getMemberId,
    transformAccounts,
    parseRef,
    formatCurrency,
    generateNonce,
} from '../../config/util';
import {showIfLocation, Link} from '../BrowserHistory/History.js';
import {bankConfig} from '../../config/constants';

class SelectAccount extends React.Component {
    constructor(props) {
        super(props);
        // Listeners
        this.handleChange = this.handleChange.bind(this);

        const country = props.params.get('country') ? props.params.get('country') : '';
        const data = props.params.get('data') ? props.params.get('data') : '';
        const ref = props.params.get('ref') ? props.params.get('ref') : '';
        const profile = props.params.get('profile') ? props.params.get('profile') : '';
        const authRequestId = props.params.get('auth-request-id') ? props.params.get('auth-request-id') : '';
        const refValues = parseRef(ref);
        const amount = formatCurrency(refValues.amount, refValues.currency);
        const bankId = getBankId();
        const tokenUsername = props.params.get('memberId')
            ? getMemberId(props.params.get('memberId'))
            : generateNonce();

        this.state = {
            clientId: '',
            accounts: [],
            country,
            bankId,
            data,
            ref,
            profile,
            authRequestId,
            amount,
            selected: '',
        };
        // Sends a request to the backend to create a few fake accounts, and updates the
        // UI to show the accounts
        bank.createAccounts(bankId, tokenUsername, country, profile, res => {
            this.setState({
                clientId: res.clientId,
                accounts: res.accounts,
            });
        });
    }

    componentDidMount() {
        if (this.props.params.get('auth-request-id')) {
            bank.getAuthRequestPayload(
                bankConfig[getBankId()].bic,
                this.props.params.get('auth-request-id'),
                res => {
                    let amount;
                    let currency;
                    if (res.payload.transfer) {
                        amount = res.payload.transfer.amount || res.payload.transfer.lifetimeAmount;
                        currency = res.payload.transfer.currency;
                    } else if (res.payload.standingOrder) {
                        amount = res.payload.standingOrder.amount;
                        currency = res.payload.standingOrder.currency;
                    } else if (res.payload.bulkTransfer) {
                        amount = res.payload.bulkTransfer.totalAmount;
                        currency = res.payload.bulkTransfer.transfers[0].currency;
                    }
                    this.setState({
                        amount: formatCurrency(
                            amount,
                            currency),
                        payload: res.payload,
                        sourceAccountHash: res.sourceAccountHash,
                        callbackUrl: res.callbackUrl,
                    });
                });
        }
    }

    getNextStep() {
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            return this.getNextStepV2();
        }
        const baseUrl = '/auth';
        const {memberId, bankId, data, ref, profile, authRequestId} = this.state;
        const defaults = {
            memberId,
            bankId,
            profile,
        };

        if (authRequestId) {
            const authRequestQueries = querystring.stringify({
                memberId: defaults.memberId,
                bankId: defaults.bankId,
                profile: defaults.profile,
                payload: window.btoa(JSON.stringify(this.state.payload)),
                accountNumber: window.btoa(JSON.stringify(this.state.selected)),
                sourceAccountHash: this.state.sourceAccountHash,
                ['auth-request-id']: authRequestId,
                ['callback-url']: this.state.callbackUrl,
            });
            return baseUrl + '/confirmation?' + authRequestQueries;
        }

        if (ref) {
            const newRef = this.encodeRef();
            return baseUrl + '/confirmation?' +
                querystring.stringify(Object.assign({}, defaults, {
                    data,
                    ref: newRef,
                }));
        }
        return baseUrl + '/confirmation?' + querystring.stringify(defaults);
    }

    getNextStepV2() {
        const defaults = {
            ['consent-request']: this.props.params.get('consent-request'),
            ['request-id']: this.props.params.get('request-id'),
            ['bank-id']: getBankId(),
            ['client-id']: this.state.clientId,
            profile: this.props.params.get('profile') || '',
            accountNumber: window.btoa(JSON.stringify(this.state.selected)),
            sourceAccountHash: this.state.sourceAccountHash,
            ['callback-url']: this.props.params && this.props.params.get('callback-url'),
        };
        const query = querystring.stringify(Object.assign({}, defaults));

        return `/authorize/v2/confirmation?${query}`;
    }

    // Decode and parse ref json, add account id key and return Base64 encoded string
    encodeRef() {
        if (!this.state.selected) return undefined;
        try {
            const ref = parseRef(this.state.ref);
            const newRef = Object.assign({}, ref, {
                accountNumber: this.state.selected,
            });
            return window.btoa(JSON.stringify(newRef));
        } catch (e) {
            return undefined;
        }
    }

    // Handle a checkbox being checked or unchecked
    handleChange(value) {
        if (this.state.selected === value) return;
        this.setState({
            selected: value,
        });
    }

    render() {
        const accountIsNotSelected = this.state.selected === '';
        const noSourceAccountHash = this.state.authRequestId && !this.state.sourceAccountHash;
        const buttonClass = 'Bank-form-submit' + ((accountIsNotSelected || noSourceAccountHash) ? ' Bank-form-submit--disabled' : '');
        const accounts = transformAccounts(
            this.state.accounts,
        );
        return (
            <div className="Token">
                <div className="Token-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Choose a Payment Account</h2>
                        <p className="Payment-amount">Amount to pay: <strong>{this.state.amount}</strong></p>
                        <p className="Payment-note">
                            {"Please select your account to pay from. You can review this payment before it's final."}
                        </p>
                        <div className="Payment-panel">
                            <h3 className="Payment-panel-title">Accounts</h3>
                            <WebCheckboxList
                                onChange={this.handleChange}
                                items={accounts}
                                showValue
                                type={WebCheckboxList.CHECKBOX_TYPE_EXCLUSIVE} />
                        </div>
                    </div>
                    <div className="Bank-terms">
                        <a href="#">{"I don't recognize this"}</a>
                    </div>
                </div>
                <div className="Token-footer-2col">
                    <div
                        onClick={() => {
                            return;
                        }}
                        className="Bank-button">
                      Cancel
                    </div>
                    {accountIsNotSelected
                        ? (
                            <div
                                className={buttonClass}>
                                Confirm
                            </div>
                        )
                        : (
                            <Link
                                className={buttonClass}
                                to={this.getNextStep()}>
                                Confirm
                            </Link>)
                    }
                </div>
            </div>);
    }
}

SelectAccount.propTypes = {
    onSuccess: PropTypes.func,
    location: PropTypes.object,
    route: PropTypes.string,
    params: PropTypes.object,
};

export default showIfLocation(SelectAccount);
