/* eslint-disable no-unused-vars*/
import style from './Login.css';
import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from '../BrowserHistory/History.js';
import {getBankId} from '../../config/util';

class Question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: props.params.get('country') ? props.params.get('country') : '',
            memberId: props.params.get('memberId'),
            bankId: getBankId(),
        };
    }
    render() {
        return (
            <div className="Login">
                <div className="Login-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Answer your memorable question</h2>
                        <div className="Bank-form-fieldset">
                            <div className="Bank-form-fieldset-field">
                                <label className="Bank-form-fieldset-field-label">
                        What was the name of your first school?
                                </label>
                                <input
                                    className="Bank-form-fieldset-field-memberId"
                                    type="text"
                                    placeholder="Hester Elementary"
                                    defaultValue="Hester Elementary" />
                            </div>
                        </div>
                        <div className="Bank-form-remember">
                            <a href="#">
                                {'Can\'t remember your memorable answer?'}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Login-footer">
                    <Link
                        to={`/auth/code?memberId=${this.state.memberId}`
                        + `&country=${this.state.country}`
                        + `&bankId=${this.state.bankId}`}
                        className="Bank-form-submit">
                    Next
                    </Link>
                </div>
            </div>
        );
    }
}

Question.propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
};

export default showIfLocation(Question);
