/* eslint-disable no-unused-vars, no-console*/
import style from './Account.css';
import React from 'react';
import PropTypes from 'prop-types';
import TokenEnablerButton from '../TokenEnablerButton';
import {showIfLocation, Link} from '../BrowserHistory/History.js';

class Account extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Account">
                <nav className="Breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/account" className="active">My Account</Link>
                </nav>
                <h1>Your Account</h1>
                <section className="Grid Grid--withGutter Grid--equalHeight">
                    <section className="Grid-cell u-sizeFill">
                        <div className="BankAccounts u-sizeFill">
                            <div className="BankAccounts-title">Bank Accounts</div>
                            <div className="Grid BankAccounts-header">
                                <span className="Grid-cell u-sizeFill">Account</span>
                                <span className="Grid-cell u-size1of4">Balance</span>
                            </div>
                            <ul className="BankAccounts-list">
                                <li className="Grid BankAccounts-list-item">
                                    <span className="Grid-cell u-sizeFill">Main Checking</span>
                                    <span className="Grid-cell u-size1of4">€19,401.15</span>
                                </li>
                                <li className="Grid BankAccounts-list-item">
                                    <span className="Grid-cell u-sizeFill">Savings</span>
                                    <span className="Grid-cell u-size1of4">€15,512.55</span>
                                </li>
                                <li className="Grid BankAccounts-list-item">
                                    <span className="Grid-cell u-sizeFill">Joint Savings</span>
                                    <span className="Grid-cell u-size1of4">€55,221.33</span>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <aside className="Grid-cell u-size1of4">
                        <div className="u-sizeFill FasterPayments">
                            <h2>Want Faster Payments?</h2>
                            <p>Token gives you the ability to pay straight from your bank with
                speed & security.
                            </p>
                            <TokenEnablerButton onSuccess={this.props.onSuccess} />
                            <p className="FasterPayments-small">
                This service is provided completely free of charge.
                                <a href="/">Need more info?</a>
                            </p>
                        </div>
                    </aside>
                </section>
            </div>
        );
    }
}
Account.propTypes = {
    onSuccess: PropTypes.func,
};

export default showIfLocation(Account);
