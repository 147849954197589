import React, {Component} from 'react';
import WebCheckbox from '../WebCheckbox';
import PropTypes from 'prop-types';

const CHECKBOX_TYPE_INCLUSIVE = 'CHECKBOX_TYPE_INCLUSIVE';
const CHECKBOX_TYPE_EXCLUSIVE = 'CHECKBOX_TYPE_EXCLUSIVE';

class WebCheckboxList extends Component {
    static get CHECKBOX_TYPE_INCLUSIVE() {
        return CHECKBOX_TYPE_INCLUSIVE;
    }
    static get CHECKBOX_TYPE_EXCLUSIVE() {
        return CHECKBOX_TYPE_EXCLUSIVE;
    }
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected,
        };
        this.onChange = this.onChange.bind(this);
    }
    onChange(id) {
        let ret;
        if (this.props.type === CHECKBOX_TYPE_EXCLUSIVE) {
            if (this.state.selected === id) return;
            ret = id;
        }
        if (this.props.type === CHECKBOX_TYPE_INCLUSIVE) {
            ret = this.state.selected instanceof Array
                ? this.state.selected
                : [];
            if (this.isSelected(id)) {
                ret = ret.filter(f => f !== id);
            } else {
                ret = ret.concat([id]);
            }
        }
        this.setState({
            selected: ret,
        });
        this.props.onChange(ret);
    }
    isSelected(id) {
        if (this.props.type === CHECKBOX_TYPE_EXCLUSIVE) {
            return this.state.selected === id;
        }
        if (this.props.type === CHECKBOX_TYPE_INCLUSIVE &&
            this.state.selected instanceof Array) {
            return this.state.selected.find(i => i === id) !== undefined;
        }
    }
    render() {
        const items = this.props.items.map( item => (
            <WebCheckbox
                key={item.id}
                checked={this.isSelected(item.id)}
                onClick={() => {
                    this.onChange(item.id);
                }}
                title={item.title}
                subtitle={this.props.showValue ? item.value : ''} />
        ),
        );
        return (
            <nav className="WebCheckboxList">
                {items}
            </nav>
        );
    }
}

WebCheckboxList.propTypes = {
    items: PropTypes.array.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.string,
        ),
    ]),
    onChange: PropTypes.func,
    showValue: PropTypes.bool,
    type: PropTypes.oneOf([CHECKBOX_TYPE_INCLUSIVE, CHECKBOX_TYPE_EXCLUSIVE]),
};

WebCheckboxList.defaultProps = {
    items: [],
    onChange: () => {},
    showValue: false,
    type: CHECKBOX_TYPE_EXCLUSIVE,
};

export default WebCheckboxList;
