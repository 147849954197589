/* eslint-disable no-unused-vars, max-len*/
import style from './SCA.css';
import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from '../BrowserHistory/History.js';
import {getBankId, getMemberId, parseRef} from '../../config/util';
import querystring from 'querystring';
import {tokenEnv} from '../../config/constants';
import bank from '../../bank/bank';
import {bankConfig} from '../../config/constants';
import axios from 'axios';

class SCA extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getNextStep = this.getNextStep.bind(this);
        this.state = {
            ready: false,
            code: 0,
            isCAF: false,
            consentRequest: {},
        };
    }

    componentDidMount() {
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            // TODO(sibinlu): consent request not found handling.
            bank.getConsentRequest(bankConfig[getBankId()].bic, this.props.params.get('request-id'), res => {
                this.setState({
                    consentRequest: res,
                    ready: true,
                });
            });
        } else if (this.props.params.get('request_id')) { // direct guest aisp (CAF)
            const requestId = this.props.params.get('request_id');
            const url = tokenEnv === 'prd' ? `https://api.token.io/token-requests/${requestId}`
                : `https://api.${tokenEnv}.token.io/token-requests/${requestId}`;
            axios.get(url)
                .then(res => {
                    this.setState({
                        ready: true,
                    });
                    let type;
                    if (res.data.tokenRequest.requestPayload.accessBody.accountResourceList) {
                        type = res.data.tokenRequest.requestPayload.accessBody.accountResourceList.resources[0].type;
                    } else return;
                    if (type === 'ACCOUNT_FUNDS_CONFIRMATION') {
                        this.setState({
                            isCAF: true,
                        });
                    }
                })
                .catch(e => console.error(e));
        } else {
            this.setState({
                ready: true,
            });
        }
    }

    getNextStep() {
        if (this.props.route === 'v2') { // Bank SDK 2.0 checkout
            return this.getNextStepV2();
        }
        const baseUrl = '/auth';
        const ref = parseRef(this.props.params.get('ref'));
        const defaults = {
            memberId: getMemberId(this.props.params.get('memberId'), this.props.params.get('data')),
            bankId: getBankId(),
            profile: this.props.params.get('profile'),
            audience: this.props.params.get('audience') || '',
            scope: this.props.params.get('scope') || '',
            nonce: this.props.params.get('nonce') || '',
            state: this.props.params.get('state') || '',
            ref: this.props.params.get('ref') || '',
            data: this.props.params.get('data') || '',
            ['linking-request-id']: this.props.params.get('linking-request-id') || '',
            ['redirect_uri']: this.props.params.get('redirect_uri') || '',
            ['request_id']: this.props.params.get('request_id') || '',
        };
        const query = querystring.stringify(Object.assign({}, defaults));
        if (this.props.params.get('auth-request-id')) { // direct guest pisp
            const authRequestQueries = querystring.stringify({
                memberId: defaults.memberId,
                bankId: defaults.bankId,
                profile: defaults.profile,
                ['auth-request-id']: this.props.params.get('auth-request-id') || '',
                ['callback-url']: this.props.params.get('callback-url') || '',
            });
            return `${baseUrl}/select-account?${authRequestQueries}`;
        }
        if (ref) {
            if (ref.accountNumber) return `${baseUrl}/confirmation?${query}`;
            return `${baseUrl}/select-account?${query}`;
        }
        if (this.props.route === 'oauth') {
            if (this.state.isCAF) {
                return `/authorize/cafConfirm?${query}`;
            }
            return `/authorize/select?${query}`;
        }
        return `${baseUrl}/token?${query}`;
    }

    getNextStepV2() {
        let callbackUrl = this.props.params.get('callback-url');
        if (!callbackUrl) {
            callbackUrl = this.state.consentRequest.tppCallback;
        }
        const requestId = this.props.params.get('request-id');

        const defaults = {
            ['consent-request']: window.btoa(JSON.stringify(this.state.consentRequest)),
            ['bank-id']: getBankId(),
            profile: this.props.params.get('profile') || '',
            ['request-id']: requestId,
            ['callback-url']: callbackUrl,
        };
        const query = querystring.stringify(Object.assign({}, defaults));

        if (this.state.consentRequest.resourceTypeList) {
            // ResourceTypeAccess request type
            return `/authorize/v2/ais-select-account?${query}`;
        } else if (this.state.consentRequest.accountResourceList) {
            // AccountResourceAccess request type
            const resources = this.state.consentRequest.accountResourceList.resources;
            if (resources.length === 1 && resources[0].type === 'ACCOUNT_FUNDS_CONFIRMATION') {
                return `/authorize/v2/caf-confirm?${query}`;
            } else {
                // Other permissions are not supported yet.
                this.throwUnimplementedError(callbackUrl, requestId);
            }
        } else if (this.state.consentRequest.transferBody
            || this.state.consentRequest.standingOrderBody
            || this.state.consentRequest.bulkTransferBody) {
            // Transfer , StandingOrder or BulkTransfer request type
            return `/authorize/v2/pis-select-account?${query}`;
        } else {
            this.throwUnimplementedError(callbackUrl, requestId);
        }
    }

    throwUnimplementedError(callbackUrl, requestId) {
        document.location = `${decodeURIComponent(callbackUrl)}?request-id=${requestId}&error=12&message=UnImplemented`;
    }

    handleChange(value) {
        this.setState({
            code: value.target.value,
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter' && this.state.ready) {
            this.props.history.push(this.getNextStep());
        }
    }

    render() {
        /* eslint-disable max-len */
        const twoFactorCodeEntered = this.state.code.toString().length === 6;
        return (
            <div className="Login">
                <div className="Login-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Two-Step Verification</h2>
                        <div className="Bank-form-fieldset-field">
                            <p className="Bank-form-instruct-sca">Please enter the 6-digit code sent to your mobile device at
                                <br /><b>(858)-***-2341</b> registered at the bank
                            </p>
                            <input
                                className="Bank-form-fieldset-field-memberId"
                                type="number"
                                onChange={this.handleChange}
                                onKeyPress={this.handleKeyPress}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
                <div className="Login-footer">
                    {this.state.ready && twoFactorCodeEntered
                        ? (
                            <Link
                                className="Bank-form-submit"
                                to={this.getNextStep()}>
                                Login
                            </Link>
                        )
                        : (
                            <div
                                className="Bank-form-submit Bank-form-submit--disabled">
                                Login
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

SCA.propTypes = {
    location: PropTypes.object,
    route: PropTypes.string,
    params: PropTypes.object,
    history: PropTypes.object,
};

export default showIfLocation(SCA);
