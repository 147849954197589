/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-unused-vars, max-len*/
import style from './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'spin.js';
import PropTypes from 'prop-types';

const BUTTON_STATUS_REGULAR = '';
const BUTTON_STATUS_LOADING = 'loading';
const BUTTON_STATUS_SUCCESS = 'success';
const BUTTON_STATUS_FAILED = 'failed';

class MagicButton extends React.Component {
    static get BUTTON_STATUS_REGULAR() {
        return BUTTON_STATUS_REGULAR;
    }
    static get BUTTON_STATUS_LOADING() {
        return BUTTON_STATUS_LOADING;
    }
    static get BUTTON_STATUS_SUCCESS() {
        return BUTTON_STATUS_SUCCESS;
    }
    static get BUTTON_STATUS_FAILED() {
        return BUTTON_STATUS_FAILED;
    }
    constructor() {
        super();
        // bind events
        this.onClick = this.onClick.bind(this);
    }
    componentDidMount() {
        const spinner = new Spinner({
            lines: 9,
            length: 16,
            width: 9,
            radius: 25,
            scale: 0.25,
            speed: 1,
            trail: 60,
            top: '50%',
            left: '50%',
            color: '#fff',
        }).spin();

        const input = this.input;

        this.spin.appendChild(spinner.el);
        if (this.input && this.props.autoFocus) {
            this.focusTimeout = setTimeout(function() {
                input.focus();
            }, 100);
        }
    }
    componentWillUnmount() {
        if (this.focusTimeout) {
            clearTimeout(this.focusTimeout);
        }
    }
    onClick() {
        if (this.props.enabled) {
            this.props.onClick();
        }
    }
    render() {
        const className = !this.props.enabled ? 'inactive' : this.props.status;
        return (
            <div>
                <button
                    className={`MagicButton ${this.props.dark ? ' MagicButton--dark' : ''} ${className}`}
                    onClick={this.onClick}
                    ref={ input => {
                        this.input = input;
                    }}>
                    <span className="label">{this.props.children || 'Continue'}</span>
                    <span className="loading" ref={ spin => this.spin = ReactDOM.findDOMNode(spin) }></span>
                    <span className="check">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
                            <path fill="#FFF" d="M4.1,8.9L1.5,6.1L0,7.6L4.1,12L14,1.6L12.5,0L4.1,8.9z" />
                        </svg>
                    </span>
                    <span className="times">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                            <path fill="#FFF" d="M1.437 0L0 1.437 4.562 6 0 10.563 1.437 12 6 7.438 10.56 12 12 10.563 7.437 6l4.56-4.562L10.563 0 6 4.563" />
                        </svg>
                    </span>
                </button>
            </div>
        );
    }
}

MagicButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    status: PropTypes.oneOf([BUTTON_STATUS_REGULAR, BUTTON_STATUS_LOADING, BUTTON_STATUS_SUCCESS, BUTTON_STATUS_FAILED]),
    enabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    dark: PropTypes.bool,
};

MagicButton.defaultProps = {
    onClick: () => {},
    status: BUTTON_STATUS_REGULAR,
    enabled: true,
    autoFocus: false,
    dark: false,
};

export default MagicButton;
