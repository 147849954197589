import axios from 'axios';
import {fankUrl} from '../config/constants';

const instance = axios.create({
    baseURL: fankUrl,
    timeout: 15000,
});

class fankApi {
    static getAccessToken(bic, memberId, profile, accounts) {
        return instance.put(`/banks/${bic}/token`, {
            memberId,
            profile,
            accounts,
        });
    }
}

export default fankApi;
