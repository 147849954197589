/* eslint-disable no-unused-vars, max-len*/
import style from './index.css';
import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from '../BrowserHistory/History.js';
import MagicButton from '../MagicButton';
import {DEMO_PORTAL_URL, DEMO_PORTAL_ITEM} from '../../config/constants';
import {getBankId} from '../../config/util';

const Links = () =>
    (
        <nav className="header--menu Grid Grid--fit">
            <Link to="/" className="Grid-cell active">HOME</Link>
            <a href="#" className="Grid-cell">BANKING</a>
            <a href="#" className="Grid-cell">CREDIT CARDS</a>
            <a href="#" className="Grid-cell">INVESTMENTS</a>
            <a href="#" className="Grid-cell">LOANS</a>
            <a href="#" className="Grid-cell">HELP</a>
            <a href="#" className="Grid-cell">CONTACT US</a>
            <a href="#" className="Grid-cell">LOCATIONS</a>
        </nav>
    );

class Outer extends React.Component {
    constructor(props) {
        super(props);
        this.onReturnClick = this.onReturnClick.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.state = {
            paired: false,
            bankId: getBankId(),
        };
    }

    onReturnClick() {
        // Link back to Token Demo menu
        window.location.href = `${DEMO_PORTAL_URL}/demos/${DEMO_PORTAL_ITEM}`;
    }
    onSuccess() {
        this.setState({
            paired: true,
        });
    }
    render() {
        const styles = {
            ReturnBar: {
                marginBottom: this.state.paired ? 0 : undefined,
            },
            Outer: {
                paddingBottom: this.state.paired ? 100 : 0,
                transition: 'padding-bottom 350ms ease-out 350ms',
            },
        };
        return (
            <div style={styles.Outer}>
                <header>
                    <div className="header--logo">
                        <div className="wrapper">
                            <div className="Grid Grid--withGutter">
                                <div className="Grid-cell u-sizeFill">
                                    <span className="logo-wrapper">
                                        <img src={`/bank-logo/${this.state.bankId}`} className="logo" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="menu-bg">
                        <Links />
                    </div>
                </header>
                <main role="main">
                    <div className="wrapper">
                        {React.Children.map(this.props.children, child => React.cloneElement(child, {
                            onSuccess: this.onSuccess,
                        }))}
                    </div>
                </main>
                <div>
                    <div className="ReturnBar" style={styles.ReturnBar}>
                        <MagicButton onClick={this.onReturnClick}>Return to Demo Portal</MagicButton>
                    </div>
                </div>
            </div>
        );
    }
}

Outer.propTypes = {
    children: PropTypes.node,
};

export default showIfLocation(Outer);
