/* eslint-disable no-unused-vars, max-len*/
import style from './Login.css';
import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from '../BrowserHistory/History.js';
import {getBankId} from '../../config/util';
import {countries} from '../../config/constants';

class LoginHSBC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: countries[0].code,
            memberId: props.params.get('memberId'),
            bankId: getBankId(),
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({country: event.target.value});
    }

    render() {
        return (
            <div className="Login">
                <div className="Login-content">
                    <div className="Bank-form">
                        <h2 className="Bank-form-title">Select country</h2>
                        <div className="Bank-form-fieldset">
                            <div className="Bank-form-fieldset-field">
                                <label className="Bank-form-fieldset-field-label">In order to link with Token.</label>
                                <select
                                    className="Bank-form-fieldset-field-select"
                                    value={this.state.country}
                                    onChange={this.handleChange}>
                                    {
                                        countries.map(country =>
                                            <option key={country.code} value={country.code}>{country.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="Bank-form-remember">
                            {'Can\'t remember something? '}
                            <a href="#">Get Help</a>
                        </div>
                    </div>
                </div>
                <div className="Login-footer">
                    <Link
                        to={`/auth/login-memberId?memberId=${this.state.memberId}`
                    + `&country=${this.state.country}`
                    + `&bankId=${this.state.bankId}`}
                        className="Bank-form-submit">
                Next
                    </Link>
                </div>
            </div>
        );
    }
}

LoginHSBC.propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
};

export default showIfLocation(LoginHSBC);
