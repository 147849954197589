/* eslint-disable no-unused-vars*/
import styles from './main.css';
/* eslint-enable no-unused-vars*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

if (!global.Intl) { // Polyfill for intl
    require('intl');
    require('intl/locale-data/jsonp/en.js');
}

const render = () => {
    ReactDOM.render(
        <App />,
        document.getElementById('app'),
    );
};

render();
