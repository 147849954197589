/* eslint-disable no-unused-vars*/
import style from './index.css';
import React from 'react';
import PropTypes from 'prop-types';

class WebCheckbox extends React.Component {
    constructor(props) {
        super(props);
        // bind events
        this.onClick = this.onClick.bind(this);
    }
    onClick() {
        if (this.props.enabled) {
            this.props.onClick();
        }
    }
    render() {
        return (
            <div>
                <table className="WebCheckbox" onClick={this.onClick}>
                    <tbody>
                        <tr>
                            <td>
                                <div className={`WebCheckbox-Control
                                        ${this.props.checked
                ? ' WebCheckbox-Control--checked'
                : ''}
                                        ${!this.props.enabled
                ? ' WebCheckbox-Control--disabled'
                : ''}
                                        `}>
                                    <span className="WebCheckbox-Check">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px" y="0px"
                                            viewBox="0 0 14 12"
                                            width="14" height="14">
                                            <path
                                                fill="#FFFFFF"
                                                d={'M4.1,8.9L1.5,6.1L0,7.6L4.1,12L'
                                                    + '14,1.6L12.5,0L4.1,8.9z'}
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </td>
                            <td
                                className="WebCheckbox-Label">
                                <div className="WebCheckbox-Label-div">
                                    <span className="WebCheckbox-title">
                                        {this.props.title}
                                    </span>
                                    {this.props.subtitle ? (
                                        <span className="WebCheckbox-subtitle">
                                            {this.props.subtitle}
                                        </span>) : null}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

WebCheckbox.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    checked: PropTypes.bool,
    enabled: PropTypes.bool,
    onClick: PropTypes.func,
};

WebCheckbox.defaultProps = {
    checked: false,
    enabled: true,
    onClick: () => {},
};

export default WebCheckbox;
